import React from 'react';
// import { useSelector } from 'react-redux';
import { EditPage } from '@equipedigital/ed-cms/components';
import * as categoriaApi from '../api/categorias';

const EditCategoria = ({ edit }) => {
  // const translations = useSelector(state => state.translations.translations)
  // const categoriasTranslations = translations['system-categorias']

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id',
    },
    {
      name: 'nome',
      type: 'text',
      // title: categoriasTranslations['edit-title'],
      title: 'Editar categoria',
      validation: {
        required: true,
      },
    },
  ];
  return (
    <EditPage
      selectedData={selectedData}
      addFunction={categoriaApi.addItem}
      fetchFunction={categoriaApi.fetchItem}
      editFunction={categoriaApi.editItem}
      edit={edit}
      url="categorias"
    />
  );
};

export default EditCategoria;
