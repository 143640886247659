import React, { useState, useEffect } from 'react'
import { contatos as contatosApi } from '@equipedigital/ed-cms/api'
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts'
import styles from '../styles/GraficoContato.module.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import ReactLoading from 'react-loading'
import "react-datepicker/dist/react-datepicker.css";

const CustomTooltip = ({ active, payload, label }) => {
  const translations = useSelector(state => state.translations.translations.graficocontato)

  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <p className="label">{label}</p>
        <p>{translations.count}: {payload[0].value}</p>
      </div>
    );
  }

  return null;
};

const GraficoContato = () => {
  const translations = useSelector(state => state.translations.translations.graficocontato)
  const date = moment();  
  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);  
  const [contatos, setContatos] = useState([])
  const [start, setStart] = useState(date.year() + '-' + (today.getMonth()+1) + '-01')
  const [end, setEnd] = useState(date.year() + '-' + (today.getMonth()+1) + '-' + lastDayOfMonth.getDate())
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    getContatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end])

  const getContatos = () => {
    setLoading(true)
    let query = '?'
    if (start) query += 'start=' + moment(start).format('YYYY-MM-DD 00:00:00')
    if (end) query += '&end=' + moment(end).format('YYYY-MM-DD 23:59:59')
    contatosApi.getCount(query).then(res => {
      setContatos(res.data)
      setLoading(false)
    }
    ).catch(e => {
      console.log(e);
      setLoading(false)
    })
  }

  return (
    <div className={styles.grafico__wrapper}>
      <p className={styles.grafico__titulo}>{translations.report}</p>
      <div className={styles.filtros}>
        <div className={styles.filtros__titulo}>
          <p>{translations.filter}</p>
        </div>
        <div className={styles.filtros__datas}>
          <div className={styles.filtros__data}>
            <DatePicker
              selected={start ? moment(start).toDate() : ''}
              dateFormat="dd/MM/yyyy"
              onChange={date => setStart(moment(date).toISOString())}
              className={"form-control"}
              placeholderText={translations.start_date}
            />
          </div>
          <div className={styles.filtros__data}>
            <DatePicker
              selected={end ? moment(end).toDate() : ''}
              dateFormat="dd/MM/yyyy"
              onChange={date => setEnd(moment(date).toISOString())}
              className={"form-control"}
              placeholderText={translations.end_date}
            />
          </div>

        </div>
      </div>

      {loading ?
        <div style={{ width: '4em', margin: '2rem auto', display: 'flex', justifyContent: 'center' }}>
          <ReactLoading color="#0586D7" type="spin" />
        </div>
        :
        <div className={styles.grafico}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={contatos.result} className="chartz" margin={{ top: 0, left: 0, right: 0, bottom: 0 }} >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="_id" />
              <YAxis allowDecimals={false} />
              <Tooltip content={<CustomTooltip />} />
              <Legend formatter={() => translations.count + ": " + contatos.total} />
              <Bar dataKey="count" fill="#0E74C8" name={translations.count} />
            </BarChart>
          </ResponsiveContainer>
        </div>}

    </div>
  )
}

export default GraficoContato
