// servicos para configurar todas as rotas de request do modulo

// importa a biblioteca axios com um interceptor customizado
import axios from './client';
import { serialize } from '@equipedigital/ed-cms/api';

// url base do módulo no backend
const url =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:3000/';
const full_url = url + 'local/';

// é necessario incluir os credentials em cada request para que o express possa adicionar os cookies necessarios para uso
const options = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

// configuracao adicional para upload de local
const optionsUpload = {
  withCredentials: true,
};

// pega todos os itens
const fetchItems = (query) => {
  let url = full_url;

  if (query) url += '?' + serialize(query);
  return axios.get(url, options);
};

// pega um unico item
const fetchItem = (id) => axios.get(full_url + id, options);

// adiciona item
const addItem = (local) => axios.post(full_url, local, options);

// edita item
const editItem = (id, local) => axios.patch(full_url + id, local, options);

// remove um item
const removeItem = (id) => axios.delete(full_url + id, options);

// atualiza status de um item
const statusItem = (id, status) =>
  axios.patch(full_url + id + '/status?status=' + status, {}, options);

// faz upload de imagem (unica)
const uploadLocal = (id, formData) =>
  axios.post(full_url + id + '/imagem', formData, optionsUpload);

// pega imagens
const getImgGaleria = (id, imgid) => full_url + id + '/galeria/' + imgid;

// deleta imagens
const removeImgGaleria = (id, imgid) =>
  axios.delete(full_url + id + '/galeria/' + imgid, options);

export {
  fetchItems,
  fetchItem,
  addItem,
  editItem,
  removeItem,
  statusItem,
  uploadLocal,
  getImgGaleria,
  removeImgGaleria,
};
