import React from 'react';
// import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { BigCard, Tabela, Header } from '@equipedigital/ed-cms/components';
import * as categoriasApi from '../api/categorias';

const Categoria = () => {
  // const translations = useSelector(state => state.translations.translations)
  // const categoriasTranslations = translations['system-categorias']

  const columns = [
    {
      // title: categoriasTranslations['manage-title'],
      title: 'Categorias',
      field: 'nome',
    },
  ];

  return (
    <React.Fragment>
      {/* <Header title={categoriasTranslations['title']} subtitle={categoriasTranslations['subtitle']}> */}
      <Header title="Categorias" subtitle="Listagem de categorias">
        <i className="fas fa-coins awesome-header"></i>
      </Header>

      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={categoriasApi.fetchItems}
            removeFunction={categoriasApi.removeItem}
            statusFunction={categoriasApi.statusItem}
            // title={categoriasTranslations['title']}
            title="Categorias"
            url={'categorias'}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Categoria;
