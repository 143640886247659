import React from 'react';
// import { useSelector } from 'react-redux';
import { EditPage } from '@equipedigital/ed-cms/components';
import * as depoimentosApi from '../api/depoimentos';

const EditDepoimentos = ({ edit }) => {
  // const translations = useSelector((state) => state.translations.translations);
  // const depoimentosTranslations = translations['system-depoimentos'];

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id',
    },
    {
      name: 'name',
      type: 'text',
      title: 'Nome Cliente',
      validation: {
        required: true,
      },
    },
    {
      name: 'text',
      type: 'tiny',
      title: 'Depoimento',
      validation: {
        required: true,
      },
    },
    {
      name: 'imagem',
      type: 'dragdrop',
      title: 'Imagem do Cliente',
      options: {
        maxFiles: 1,
      },
      validation: {
        required: true,
      },
    },
  ];

  return (
    <EditPage
      title={edit ? 'Editar depoimento' : 'Adicionar depoimento'}
      selectedData={selectedData}
      addFunction={depoimentosApi.addItem}
      fetchFunction={depoimentosApi.fetchItem}
      editFunction={depoimentosApi.editItem}
      uploadFunction={depoimentosApi.uploadDepoimentos}
      edit={edit}
      url="depoimentos"
    />
  );
};

export default EditDepoimentos;
