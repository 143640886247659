import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { MainLayout } from '@equipedigital/ed-cms/layouts';

import IndexPage from '../pages/Index';
import NotFoundPage from '../pages/NotFound';

import ContentsPage from '../pages/Contents';
import EditContentPage from '../pages/EditContent';

import NoticiasPage from '../pages/Noticias';
import EditNoticiasPage from '../pages/EditNoticia';

import CategoriasPage from '../pages/Categorias';
import EditCategoriasPage from '../pages/EditCategorias';

import LocalPage from '../pages/Local';
import EditLocalPage from '../pages/EditLocal';

import DepoimentosPage from '../pages/Depoimentos';
import EditDepoimentosPage from '../pages/EditDepoimentos';

import {
  UsersPage,
  EditUserPage,
  EditMetatagsPage,
  EditConfigPage,
  EditSocialMidiaPage,
  TranslationsPage,
  EditTranslationsPage,
  Contatos,
  ViewContatosPage,
} from '@equipedigital/ed-cms/pages';

import {
  startSetLang,
  startFetchTranslations,
} from '@equipedigital/ed-cms/actions';

import { useSelector } from 'react-redux';

import '@equipedigital/ed-cms/layouts/main.css';
import '@equipedigital/ed-cms/pages/main.css';
import '@equipedigital/ed-cms/components/main.css';

const SystemRouter = () => {
  const dispatch = useDispatch();
  const url =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL
      : 'http://localhost:3000/';
  const full_url = url + 'metatags/';
  const full_url_config = url + 'config/';
  const full_url_socialmidia = url + 'socialmidia/';
  const { loggedIn, user } = useSelector((state) => state.auth);
  const translations = useSelector(
    (state) => state.translations.translations.sidebar
  );

  useEffect(() => {
    if (user && user.preferences) {
      dispatch(startSetLang(user.preferences.lang));
      dispatch(startFetchTranslations(process.env.REACT_APP_API_URL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const navigation = [
    {
      title: translations.site_news,
      link: [
        {
          link: '/noticias' || 'Noticias',
          title: translations.site_news,
        },
        {
          link: '/categorias' || 'Categorias',
          title: translations.site_categories,
        },
      ],
    },
    {
      link: '/local',
      title: translations.site_local || 'Local',
    },
    {
      link: '/depoimentos',
      title: translations.site_local || 'Depoimentos',
    },
    {
      link: '/contents',
      title: translations.site_simple_content,
    },
  ];
  return loggedIn ? (
    <Router>
      <MainLayout navigation={navigation}>
        <Switch>
          <Route path="/" component={IndexPage} exact />

          <Route
            path="/edit/contents/:id"
            render={(props) => <EditContentPage {...props} edit={true} />}
          />
          <Route path="/add/contents" component={EditContentPage} />
          <Route path="/contents" component={ContentsPage} />

          <Route
            path="/edit/noticias/:id"
            render={(props) => <EditNoticiasPage {...props} edit={true} />}
          />
          <Route path="/add/noticias" component={EditNoticiasPage} />
          <Route path="/noticias" component={NoticiasPage} />

          <Route
            path="/edit/local/:id"
            render={(props) => <EditLocalPage {...props} edit={true} />}
          />
          <Route path="/add/local" component={EditLocalPage} />
          <Route path="/local" component={LocalPage} />

          <Route
            path="/edit/depoimentos/:id"
            render={(props) => <EditDepoimentosPage {...props} edit={true} />}
          />
          <Route path="/add/depoimentos" component={EditDepoimentosPage} />
          <Route path="/depoimentos" component={DepoimentosPage} />

          <Route
            path="/edit/categorias/:id"
            render={(props) => <EditCategoriasPage {...props} edit={true} />}
          />
          <Route path="/add/categorias" component={EditCategoriasPage} />
          <Route path="/categorias" component={CategoriasPage} />

          <Route path="/edit/metatags">
            <EditMetatagsPage full_url={full_url} />
          </Route>
          <Route path="/edit/config">
            <EditConfigPage full_url={full_url_config} />
          </Route>
          <Route path="/edit/socialmidia">
            <EditSocialMidiaPage full_url={full_url_socialmidia} />
          </Route>

          <Route path="/edit/users/:id">
            <EditUserPage api_url={url} edit={true} />
          </Route>
          <Route path="/add/users">
            <EditUserPage api_url={url} />
          </Route>
          <Route path="/users">
            <UsersPage api_url={url} />
          </Route>

          <Route path="/edit/contatos/:id">
            <ViewContatosPage api_url={url} edit={true} />
          </Route>
          <Route path="/contatos">
            <Contatos api_url={url} />
          </Route>

          <Route path="/edit/translations/:id">
            <EditTranslationsPage api_url={url} />
          </Route>
          <Route path="/translations">
            <TranslationsPage api_url={url} />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </MainLayout>
    </Router>
  ) : (
    <Redirect to="/login" />
  );
};

export default SystemRouter;
