import React from 'react';
// import { useSelector } from 'react-redux';
import { EditPage } from '@equipedigital/ed-cms/components';
import * as localApi from '../api/local';

const EditLocal = ({ edit }) => {
  /*
  const translations = useSelector((state) => state.translations.translations);
  const localTranslations = translations['system-local']
  */

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id',
    },
    {
      name: 'title',
      type: 'text',
      title: 'Nome do local',
      validation: {
        required: true,
      },
      options: {
        maxLength: '150',
      },
    },
    {
      name: 'type',
      type: 'select',
      title: 'Tipos',
      selectOptions: [
        {
          value: 'R',
          label: 'Restaurantes',
        },
        {
          value: 'E',
          label: 'Espaços',
        },
      ],
      options: {
        populated: true,
      },
    },
    {
      name: 'description',
      type: 'tiny',
      title: 'Informações sobre o local',
    },
    {
      name: 'galeria',
      type: 'dragdrop',
      title: 'Galeria',
      options: {
        customRemoveImgFromArray: (id, imgid) =>
          localApi.removeImgGaleria(id, imgid),
      },
    },
    {
      name: 'cardapios',
      type: 'tiny',
      title: 'Informações sobre o cardápio',
      conditional: [
        {
          name: 'type',
          equal: 'E',
        },
      ],
    },
    {
      name: 'video',
      type: 'text',
      title: 'Vídeo do local',
      conditional: [
        {
          name: 'type',
          equal: 'E',
        },
      ],
    },
  ];

  return (
    <EditPage
      title={edit ? 'Editar local' : 'Adicionar local'}
      subtitle={'Informações necessárias para a edição do local'}
      selectedData={selectedData}
      addFunction={localApi.addItem}
      fetchFunction={localApi.fetchItem}
      editFunction={localApi.editItem}
      uploadFunction={localApi.uploadLocal}
      edit={edit}
      url="local"
    />
  );
};

export default EditLocal;
