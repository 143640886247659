import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux'
import { EditPage } from '@equipedigital/ed-cms/components';
// import { EditPage } from '../components/EditPage'
import * as noticiaApi from '../api/noticias';
import { fetchItems } from '../api/categorias';

const EditNoticia = ({ edit }) => {
  // const translations = useSelector(state => state.translations.translations)
  // const noticiasTranslations = translations['system-noticias']

  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    fetchItems().then((res) => {
      let categoriasOptions = [];
      res.data.forEach((categoria) => {
        categoriasOptions.push({ value: categoria._id, label: categoria.nome });
      });
      setCategorias(categoriasOptions);
    });
  }, []);

  const selectedData = [
    {
      name: '_id',
      type: 'hidden',
      title: 'Id',
    },
    {
      name: 'titulo',
      type: 'text',
      // title: noticiasTranslations['edit-title'],
      title: 'Título da notícia',
      validation: {
        required: true,
      },
      options: {
        maxLength: '150',
      },
      tooltip: {
        // text: noticiasTranslations['edit-title-p']
        text: 'Editar texto',
      },
    },
    {
      name: 'subtitulo',
      type: 'text',
      // title: noticiasTranslations['edit-subtitulo'],
      title: 'Subtítulo',
      options: {
        optional: true,
      },
    },
    {
      name: 'categoria',
      type: 'select',
      // title: noticiasTranslations['edit-categoria'],
      title: 'Categoria',
      selectOptions: categorias,
      options: {
        populated: true,
      },
    },
    {
      name: 'data_noticia',
      type: 'date',
      options: {
        formatHora: 'Pp',
      },
      // title: noticiasTranslations['edit-datanoticia']
      title: 'Data da notícia',
    },
    {
      name: 'data_publicacao',
      type: 'date',
      options: {
        formatHora: 'Pp',
      },
      // title: noticiasTranslations['edit-datapublicacao'],
      title: 'Data de publicação',
      // subtitle: noticiasTranslations['edit-datapublicacao-s'],
      subtitle: 'Data em que irá mostrar a notícia no site.',
      options: {
        optional: true,
      },
    },
    {
      name: 'destacar',
      type: 'checkbox',
      // title: noticiasTranslations['edit-destacar'],
      title: 'Destacar',
      // subtitle: noticiasTranslations['edit-destacar-s']
      subtitle: 'Notícia que ficará em destaque no site.',
    },
    {
      name: 'corpo',
      type: 'tiny',
      // title: noticiasTranslations['edit-corpo'],
      title: 'Conteúdo da notícia',
      conditional: [
        {
          name: 'categoria',
          nequal: '61201ccf67375f2f903d4790',
        },
      ],
      options: {
        optional: true,
      },
    },
    {
      name: 'video',
      type: 'text',
      // title: noticiasTranslations['edit-link'],
      title: 'Vídeo',
      conditional: [
        {
          name: 'categoria',
          equal: '61201ccf67375f2f903d4790',
        },
      ],
      // options: {
      //   optional: true
      // }
    },
    {
      name: 'img',
      type: 'dragdrop',
      // title: noticiasTranslations['edit-image'],
      title: 'Imagem da notícia',
      options: {
        maxFiles: 1,
      },
      validation: {
        required: true,
      },
      conditional: [
        // { /** VIDEO NECESSITA DE UMA THUMBNAIL */
        //   name: 'categoria',
        //   nequal: "61201ccf67375f2f903d4790"
        // },
        {
          name: 'categoria',
          nequal: '60b7b815cb748f4d8447b8b1',
        },
      ],
    },
    {
      name: 'galeria',
      type: 'dragdrop',
      // title: noticiasTranslations['edit-galeria'],
      title: 'Galeria',
      conditional: [
        {
          name: 'categoria',
          equal: '60b7b815cb748f4d8447b8b1',
        },
      ],
      options: {
        customRemoveImgFromArray: (id, imgid) =>
          noticiaApi.removeImgGaleria(id, imgid),
      },
    },
    {
      name: 'url',
      type: 'text',
      // title: noticiasTranslations['edit-url'],
      title: 'URL amigável',
      // subtitle: noticiasTranslations['edit-url-s'],
      subtitle: 'Está sera a URL que irá mostrar.',
      validation: {
        required: true,
      },
      tooltip: {
        // text: noticiasTranslations['edit-url-p']
        text: 'Edit-url-p',
      },
    },
  ];
  return (
    <EditPage
      // title={edit ? noticiasTranslations['editpage-title'] : noticiasTranslations['addpage-title']}
      // subtitle={edit ? noticiasTranslations['editpage-subtitle'] : noticiasTranslations['addpage-subtitle']}
      title={edit ? 'Editar notícia' : 'Adicionar título'}
      subtitle={
        edit
          ? 'Aqui você irá modificar a notícia cadastrada.'
          : 'Adicionar subtítulo'
      }
      selectedData={selectedData}
      addFunction={noticiaApi.addItem}
      fetchFunction={noticiaApi.fetchItem}
      editFunction={noticiaApi.editItem}
      uploadFunction={noticiaApi.uploadNoticia}
      edit={edit}
      url="noticias"
    />
  );
};

export default EditNoticia;
