import React from 'react';
import { Container } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import { BigCard, Tabela, Header } from '@equipedigital/ed-cms/components';
import * as localApi from '../api/local';

const Local = () => {
  // const translations = useSelector((state) => state.translations.translations);
  // const localTranslations = translations['system-local'];

  const columns = [
    {
      title: 'Local',
      field: 'title',
      sorting: true,
    },
    {
      title: 'Tipo',
      field: 'type',
      sorting: true,
      render: (rowData) => (rowData.type == 'R' ? 'Restaurante' : 'Espaço'),
    },
  ];

  return (
    <React.Fragment>
      <Header title={'Local'} subtitle={'Listagem de locais'}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>

      <Container className="page-content">
        <BigCard>
          <Tabela
            url={'local'}
            columns={columns}
            fetchFunction={localApi.fetchItems}
            removeFunction={localApi.removeItem}
            statusFunction={localApi.statusItem}
            enableSearch
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Local;
