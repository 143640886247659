import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap';
import { BigCard, Tabela, Header } from '@equipedigital/ed-cms/components';
import * as noticiasApi from '../api/noticias';
import { fetchItems } from '../api/categorias';

const Noticias = () => {
  // const translations = useSelector(state => state.translations.translations)
  // const noticiasTranslations = translations['system-noticias']
  const [categorias, setCategorias] = useState({});

  useEffect(() => {
    fetchItems().then((res) => {
      let categoriasOptions = {};
      res.data.forEach((categoria) => {
        categoriasOptions[categoria._id] = categoria.nome;
      });
      setCategorias(categoriasOptions);
    });
  }, []);

  const columns = [
    {
      // title: noticiasTranslations['manage-image'],
      title: 'Imagem',
      field: '_id',
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: (rowData) => {
        return (
          <img
            className="noticia-img"
            src={noticiasApi.getImgNoticia(rowData._id)}
            alt=""
          />
        );
      },
    },
    {
      // title: noticiasTranslations['manage-title'],
      title: 'Título',
      field: 'titulo',
      sorting: true,
    },
    {
      // title: noticiasTranslations['manage-category'],
      title: 'Categorias',
      field: 'categoria',
      center: true,
      sorting: categorias,
      // render: (rowData) => rowData.categoria.nome,
      render: (rowData) =>
        rowData.categoria.nome !== null ? rowData.categoria.nome : '',
      // render: (rowData) => console.log(rowData.categoria.nome),
    },
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      {/* <Header title={noticiasTranslations['title']} subtitle={noticiasTranslations['subtitle']}> */}
      <Header title="Notícias" subtitle="Listagem  das notícias">
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={'noticias'}
            columns={columns}
            fetchFunction={noticiasApi.fetchItems}
            removeFunction={noticiasApi.removeItem}
            statusFunction={noticiasApi.statusItem}
            enableSearch
            enableDatas
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Noticias;
