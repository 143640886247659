import React from 'react';
import { Container } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import { BigCard, Tabela, Header } from '@equipedigital/ed-cms/components';
import * as depoimentosApi from '../api/depoimentos';

const Depoimentos = () => {
  // const translations = useSelector((state) => state.translations.translations);
  // const depoimentosTranslations = translations['system-depoimentos'];

  const columns = [
    {
      title: 'Nome Cliente',
      field: 'name',
    },
    {
      title: 'Depoimento',
      field: 'text',
    },
  ];

  return (
    <React.Fragment>
      <Header
        title={'Depoimentos'}
        subtitle={'Lista de depoimentos relacionadas ao cliente'}
      >
        <i className="fas fa-coins awesome-header"></i>
      </Header>

      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={depoimentosApi.fetchItems}
            removeFunction={depoimentosApi.removeItem}
            statusFunction={depoimentosApi.statusItem}
            title={'Depoimentos'}
            url={'depoimentos'}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Depoimentos;
