import axios from './client';
import { serialize } from '@equipedigital/ed-cms/api';

const url =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:3000/';
const full_url = url + 'depoimentos/';

//É necessário incluir os credentials em cada request para que o express possa
//adicionar os cookies necessários para uso
const options = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

//configuração adicional para upload de depoimentos
const optionsUpload = {
  withCredentials: true,
};

//pega todos os items
const fetchItems = (query) => {
  let url = full_url;
  if (query) url += '?' + serialize(query);
  return axios.get(url, options);
};

//pega um unico item
const fetchItem = (id) => axios.get(full_url + id, options);

//adiciona item
const addItem = (depoimento) => axios.post(full_url, depoimento, options);

//edita um item
const editItem = (id, depoimento) =>
  axios.patch(full_url + id, depoimento, options);

//remove um item
const removeItem = (id) => axios.delete(full_url + id, options);

//atualizar status de um item
const statusItem = (id, status) =>
  axios.patch(full_url + id + '/status?status=' + status, {}, options);

//faz o upload de imagem (unica)
const uploadDepoimentos = (id, formData) =>
  axios.post(full_url + id + '/imagem', formData, optionsUpload);

//pega imagem de noticia
const getFileDepoimentos = (id) => full_url + id + '/imagem';

export {
  fetchItems,
  fetchItem,
  addItem,
  editItem,
  removeItem,
  statusItem,
  uploadDepoimentos,
  getFileDepoimentos,
};
