import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { BigCard } from '@equipedigital/ed-cms/components'
import { useSelector } from 'react-redux'
import styles from '../styles/IndexPage.module.scss'
import GraficoContato from '../components/GraficoContato'
import LittleCard from '../components/LittleCard'
import { toggleAssembleia } from '../api/socialmidia'
import axios from '../api/client'

const Index = () => {
  const { system,index } = useSelector(state => state.translations.translations)
  const { user } = useSelector(state => state.auth)

  return (
    <React.Fragment>
      <Container className={styles.title}>
        <h1>
          <span >{system['dash-hello']}</span>, {user.name}
        </h1>
      </Container>
      <div className="container page-content">
        <Row>
          <Col className="mb-4">
            <BigCard>
              <h2>{index.stats}</h2>
              <GraficoContato />
            </BigCard>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Index
